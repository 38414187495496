header.header{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 0 20px;
  @include break-max($tab){
    top: 0;
    padding: 0;
  }

  .header--settings-block{
    position: fixed;
    width: 100%;
    height: 65px;
    top: 0;
    left: 0;
    border-bottom: 1px solid transparent;
    background: $second-brand-color;
    z-index: 10;

    @include break-max($mob){
      width: 100%;
      position: relative;
      height: 50px;
      border-bottom: 1px solid rgba($dark-blue, 0.2);
    }

    .header--settings-container{
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: all 1s ease;

      @include break-max($mob){
        height: 50px;
      }

      .header--togg-menu{
        display: none;

        @include break-max($mob){
          display: inline-flex;
          margin-right: 15px;
          top: 0;
          height: 15px;
        }
      }
    }
  }

  .header--logo{
    margin-right: 15px;

    @include break-max($mob){
      width: 100%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }

    .header--logo-image{
      display: block;
      width: 100%;
      max-width: 140px;

      @include break-max($mob){
        margin: 0 auto;
        max-width: 110px;
      }
    }
  }

  &.header--stickly{
    background: rgba($dark-blue, 0.5);
    .header--settings-block{
      .header--togg-menu{
        @include break-max($mob){
          display: inline-flex;
          position: fixed;
          top: 15px;
          left: 15px;
          height: 50px;
          width: 50px;
          background: rgba($second-brand-color, 0.8);
          border-radius: 50%;
          justify-content: center;
          z-index: 100;
          transition: top 1s ease;
          mix-blend-mode: multiply;
        }
      }
    }

    //.header--navigation-block{
    //  .header--navigation-menu-container{
    //    min-height: unset;
    //
    //    .header--togg-menu,
    //    .main-menu-short{
    //
    //    }
    //  }
    //}
  }
}

.header--user-block{
  display: flex;
  margin-left: auto;
  margin-right: 0;
  gap: 12px;
  @include break-max($mob){
    display: none;
  }

  .open-sub-menu-login-bar{
    display: none;
    width: 30px;
    height: 40px;
    padding: 0;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    box-shadow: none;
    outline: none;

    @include break-max($mob){
      display: inline-flex;
    }

    &:hover, &:active{
      border: none;
      background: transparent;
      box-shadow: none;
      outline: none;
    }

    .icon{
      //font-weight: bold;
      //font-size: 20px;
      color: $primary-blue;
      width: 20px;
      height: 20px;
    }
  }

  .btn{
    margin: 4px 10px;
    font-weight: 400;
    min-height: 40px;

    &.btn--login{
      min-width: 140px;
      position: relative;
    }
    &.open-sub-menu-login-bar{
      padding-right: 0;
      margin-right: 0;
    }
  }

  .hide-on-mob{
    @include break-max($mob){
      display: none !important;
    }
  }
}

.header--anchor-nav{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-right: 14px;
  @include break-max($mob){
    display: none;
  }

  .dropdown{
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0;
    .dropdown-toggle{
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      background: transparent;
      border: none;
      box-shadow: none;
      outline: none;

      .icon{
        display: inline-block;
        min-width: 12px;

        svg{
          height: 14px;
        }
      }
    }
    .dropdown-menu{
      position: absolute;
      min-width: 144px;
      left: 0;
      top: 100%;
      padding: 12px 10px;
      background: #FFFFFF;
      box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
      border-radius: 5px;
      transform: translateY(22px);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-out;
      z-index: 999;

      .dropdown-item{
        display: flex;
        align-items: center;
        width: 100%;
        padding: 4px 10px;
        height: 34px;
        white-space: nowrap;
        font-size: 15px;
        background: #ffff;
        border-radius: 2px;
        color: $dark-blue;

        &:hover{
          background: $second-brand-color;
          color: $dark-blue;
        }
      }
    }

    &:hover{
      .dropdown-menu{
        visibility: visible;
        transform: translateY(0px);
        opacity: 1;
      }
    }
  }

  .dropdown > .dropdown-toggle:focus~.dropdown-menu{
    visibility: visible;
    transform: translateY(0px);
    opacity: 1;
  }

  &-item{
    color: $dark-blue;
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    display: none;
    padding: 5px;
    transition: all ease 0.3s;
    @include break-min($tab){
      display: inline-block;
    }

    &:hover{
      text-decoration: none;
    }
  }
}

.header--mob-togg-menu,
.header--togg-menu {
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  text-decoration: none;
  box-shadow: none;
  background: transparent;
  padding: 0;
  height: 30px;
  color: $dark-blue;
  line-height: 1;
  transition: all 0.2s ease-in-out;
}

.header--mob-togg-menu .hamburger-box,
.header--togg-menu .hamburger-box{
  display: inline-block;
  position: relative;
  width: 23px;
  height: 15px;
  transition: .4s ease-in-out;
  margin-right: 15px;
  z-index: 1;

  @include break-max($mob){
    margin-right: 0;
    //width: 26px;
  }
}

.header--mob-togg-menu .txt-box,
.header--togg-menu .txt-box{
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1;

  @include break-max($mob){
    display: none;
  }
}

.header--mob-togg-menu .hamburger-box span,
.header--togg-menu .hamburger-box span{
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .35s ease-in-out;
}

.header--mob-togg-menu .hamburger-box span:nth-child(1),
.header--togg-menu .hamburger-box span:nth-child(1) {
  top: 0;
}

.header--mob-togg-menu .hamburger-box span:nth-child(2),
.header--togg-menu .hamburger-box span:nth-child(2) {
  width: 100%;
  top: 7px;
}
.header--mob-togg-menu:hover .hamburger-box span:nth-child(2),
.header--togg-menu:hover .hamburger-box span:nth-child(2) {
  left: -10%;
}

.header--mob-togg-menu .hamburger-box span:nth-child(3),
.header--togg-menu .hamburger-box span:nth-child(3) {
  top: 15px;
}

.header--mob-togg-menu.open .hamburger-box span:nth-child(1),
.header--togg-menu.open .hamburger-box span:nth-child(1) {
  top: 12px;
  left: 5px;
  width: 80%;
  background: $primary-red;
  transform: rotate(135deg);

  @include break-max($mob){
    left: 3px;
  }
}

.header--mob-togg-menu.open .hamburger-box span:nth-child(2),
.header--togg-menu.open .hamburger-box span:nth-child(2) {
  left: -60px;
  opacity: 0;
}

.header--mob-togg-menu.open .hamburger-box span:nth-child(3),
.header--togg-menu.open .hamburger-box span:nth-child(3) {
  top: 12px;
  left: 5px;
  width: 80%;
  background: $primary-red;
  transform: rotate(-135deg);

  @include break-max($mob){
    left: 3px;
  }
}