.ap{
	&--success-block{
		position: relative;
		text-align: center;
		margin-bottom: 25px;
		&-circle{
			display: inline-flex;
			align-items: center;
			justify-content: center;
			line-height: 1;
			height: 160px;
			width: 160px;
			border-radius: 50%;
			box-shadow: 0px 10px 50px -16px rgba($dark-blue, 0.2);
		}

		&-checkbox{
			position: relative;
			width: 90px;
			height: 90px;
			background: #fff;
			overflow: hidden;
			-webkit-clip-path: polygon(0 66%, 14% 43%, 40% 61%, 75% 1%, 100% 16%, 50% 100%);
			clip-path: polygon(0 66%, 14% 43%, 40% 61%, 75% 1%, 100% 16%, 50% 100%);

			&-wrapper{
				filter: drop-shadow(0 3px 10px rgba($dark-blue, 0.15));
				transform: translateX(4px) rotate(8deg);
			}

			&_ok{
				position: absolute;
				top: 100%;
				width: 100%;
				height: 100%;
				@include gradient--green;
				left: 0;
				transition: all 0.4s ease;

				&.in{
					top: 0;
				}
			}
		}
	}
}