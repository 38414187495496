.footer {
  position: relative;
  padding: 60px 0 50px;
  background: $second-brand-color;
  border-top: 1px solid $second-brand-color;
  z-index: 2;

  .p{
    color: $dark-blue;
    opacity: 0.9;
    font-size: 12px;
  }
  .p--small {
    font-size: 12px;
    color: $secondary-text-color;
  }

  &__top{
    margin-bottom: 15px;
    border-bottom: 1px solid #E7ECF7;
    padding-bottom: 16px;
  }

  &__top-row{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 22px;
    @include break-max($tab){
      flex-direction: column;
    }
  }

  &__top-nav{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 22px;

    a{
      font-size: 16px;
      color: $dark-blue;
      display: block;
      padding: 2px;
      transition: all .2s ease;
      text-decoration: none;
      &:hover{
        color: $primary-blue;
        text-decoration: none;
      }
    }
  }

  &__location{
    flex: 1;
    display: flex;
    align-items: center;
    gap: 0 22px;
    flex-wrap: wrap;
    &-block{

    }
  }

  &__social {
    margin: 0;
    padding: 5px 0;
    border-top: none;
    border-bottom: none;

    &-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 200px;
      margin: 0 auto;
      gap: 22px;
    }

    &-link {
      display: flex;
      color: $social-link-color;
      text-decoration: none;

      &:hover{
        color: darken($social-link-color, 6%);
      }

      svg {
        height: 22px;
      }
    }
  }

  &__bottom {
    text-align: center;

    &-logo {
      display: flex;
      max-width: 134px;
      margin: 25px auto;
    }

    &-copyrights {
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      &--small {
        font-size: 12px;
        color: $secondary-text-color;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}