@import "../../scss/base/normalize";
@import "../../scss/base/reset-css";
@import "/base/vars";
@import "../../scss/base/ui-kit--buttons";
@import "../../scss/base/ui-kit--forms";
@import "../../scss/base/ui-kit--typography";
@import "/base/ui-kit-button";
@import "../../scss/base/components/ui-components/index";
@import "../../scss/base/main";
@import "../../scss/base/grid";
@import "../../scss/base/font-utils";
@import "../../scss/modules/header-and-footer/liveChatCard";
@import "/base/components/popup-success-informer";
*{
	box-sizing: border-box;
}
body{
	padding-top: 0;
}

.highlight{
	color: $primary-blue;
}

.promo-video__btn {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 90px;
	height: 90px;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50px;
	background: rgba(37, 50, 70, 0.3);
	box-shadow: 2px 4px 15px 3px rgba(45, 53, 71, 0.1);
	cursor: pointer;
	text-decoration: none;
	@include transition;
	z-index: 4;

	&:hover {
		background: rgba(37, 50, 70, 0.5);
	}

	&-icon {
		margin-left: 3px;
		font-size: 24px;
		height: 24px;
		color: $white;

		path{
			fill: #fff;
		}
	}
}

img{
	height: auto;
	width: auto;
	max-width: 100%;
}

// Header language
.header__language {
  display: none;
}

//Import base fonts
$font-path: '/build/fonts/';
@include font-face("Mulish", "#{$font-path}mulish/Mulish-Regular", 400, inherit, eot woff2 woff ttf otf svg);
@include font-face("Mulish", "#{$font-path}mulish/Mulish-Bold", 700, inherit, eot woff2 woff ttf otf svg);
@include font-face("Noto Sans", "#{$font-path}noto-sans/NotoSans-Regular", 400, inherit, eot woff2 woff ttf otf svg);
@include font-face("Caveat", "#{$font-path}caveat/Caveat-Bold", 700, inherit, eot woff2 woff ttf otf svg);

// Modules from header & footer
@import "base/components/header";
@import "base/components/footer";
@import "base/components/main-menu";
//@import "../../scss/modules/header-and-footer/language-selector";