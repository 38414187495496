// use in uikit.scss
// Responsiveness breakpoints
@import "../../base/vars";
$tab-xl: 1200px;

@mixin gradient--dark-blue{
  background: linear-gradient(139.93deg, #3873CD 18.92%, #0B3052 87.06%);
}
@mixin gradient--blue{
  background: linear-gradient(254.44deg, #06ADEF -55.67%, rgba(6, 173, 239, 0) 36.52%),
  linear-gradient(95.84deg, rgba(0, 211, 139, 0.5) -56.62%, rgba(0, 211, 139, 0) 68.64%), #06ADEF;
  background-blend-mode: multiply, normal, normal;
  transition: all 0.3s ease;
}
@mixin gradient--green{
  background: linear-gradient(281.44deg, rgba(0, 211, 139, 0.5) 33.12%, rgba(6, 173, 239, 0.7) 100%), #00D38B;
  transition: all 0.3s ease;
}

@mixin gradient--blue-hover{
  background: linear-gradient(95.84deg, rgba(0, 211, 139, 0.5) -56.62%, rgba(0, 211, 139, 0) 68.64%),
  linear-gradient(254.44deg, #06ADEF -55.67%, rgba(6, 173, 239, 0) 36.52%), #06ADEF;
  background-blend-mode: multiply, normal, normal;
  transition: all 0.3s ease;
}

@mixin gradient--green-hover{
  background: linear-gradient(281.44deg, rgba(6, 173, 239, 0.5) 104.11%, rgba(0, 211, 139, 0.5) 33.12%), #00D38B;
  transition: all 0.3s ease;
}


